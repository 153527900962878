import * as React from "react";
import { useHistory } from "react-router-dom";

import useAuth from "../hooks/useAuth";

// For routes that can only be accessed by authenticated users
function AuthGuard({ children }) {
    const history = useHistory();
    const { isAuthenticated, isInitialized } = useAuth();
    if (isInitialized && !isAuthenticated) {
        history.push("/auth/sign-in");
    }

    return <React.Fragment>{children}</React.Fragment>;
}

export default AuthGuard;

import React from "react";

import {
    Button,
    Col,
    Row,
} from "reactstrap";

import { RefreshCw } from "react-feather";

const Header = () => {
    const username = localStorage.getItem('userName');

    return (
        <Row className="mb-2 mb-xl-4">
            <Col xs="auto" className="d-none d-sm-block">
                <h3>Welcome back, <span style={{textTransform: 'capitalize'}}>{username}</span></h3>
            </Col>

            <Col xs="auto" className="ml-auto text-right mt-n1">
                <Button color="primary" className="shadow-sm">
                    <RefreshCw className="feather" />
                </Button>
            </Col>
        </Row>
    );
};

export default Header;

import jwtDecode from "jwt-decode";
import { verify, sign } from "jsonwebtoken";
import { setCookie, removeCookie } from "../functions/cookieWrapper"

const isValidToken = (accessToken) => {
    if (!accessToken) {
        return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
};

const handleTokenExpired = (exp) => {
    let expiredTimer;

    window.clearTimeout(expiredTimer);
    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime;

    expiredTimer = window.setTimeout(() => {
        console.log("expired");
    }, timeLeft);

};

const setSession = (accessToken) => {
    if (accessToken) {
        setCookie("token", accessToken);
        // This function below will handle when token is expired
        const { exp } = jwtDecode(accessToken);
        handleTokenExpired(exp);

    } else {
        removeCookie("token");

    }
};

const getUserFromToken = (accessToken) => {
    if (accessToken) {
        const { user_id } = jwtDecode(accessToken);
        return user_id;

    }
}

const getClientFromToken = (accessToken) => {
    if (accessToken) {
        const { client } = jwtDecode(accessToken);
        return client;

    }
}

const getFullNameFromToken = (accessToken) => {
    if (accessToken) {
        const { name } = jwtDecode(accessToken);
        return name;

    }
}

const getUserNameFromToken = (accessToken) => {
    if (accessToken) {
        const { username } = jwtDecode(accessToken);
        return username;

    }
}

export {
    verify,
    sign,
    isValidToken,
    setSession,
    getUserFromToken,
    getFullNameFromToken,
    getClientFromToken,
    getUserNameFromToken,
};

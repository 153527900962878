import React, {Fragment, useEffect} from "react";
import { toastr } from "react-redux-toastr";

const Notifications = (props) => {
    const notification = {
        title: "",
        message: "",
        type: "success",
        timeOut: 5000,
        showCloseButton: true,
        progressBar: true,
        position: "top-right"
    };

    const showToastr = (data) => {
        const options = {
            ...notification
        };

        const typeChk = data.type || notification.type;
        const toastrInstance =
            typeChk === "info"
                ? toastr.info
                : typeChk === "warning"
                    ? toastr.warning
                    : typeChk === "error"
                        ? toastr.error
                        : toastr.success;

        toastrInstance(
            data.title,
            data.message || "Unspecified Error State!",
            options
        );
    }

    useEffect(() => {
        if (typeof props.notifications === 'object' && props.notifications !== null && !Array.isArray(props.notifications)) {
            if (Array.isArray(props.notifications.messages) && props.notifications.messages.length > 0) {
                for (const noteItem of props.notifications.messages) {
                    if (typeof noteItem === 'object' && noteItem !== null) {
                        showToastr({
                            type: props.notifications.type,
                            message: noteItem.message,
                            title: noteItem.title || props.notifications.title || 'Error!'
                        })
                    } else {
                        showToastr({
                            type: props.notifications.type,
                            message: noteItem,
                            title: props.notifications.title || 'Error!'
                        })
                    }

                }

            } else if (props.notifications.message !== undefined && props.notifications.message !== '') {
                showToastr({
                    type: props.notifications.type,
                    message: props.notifications.message,
                    title: props.notifications.title || 'Error!'
                });

            }

        } else if (typeof props.errMsg === 'object' && props.errMsg !== null && !Array.isArray(props.errMsg)) {
            if (props.errMsg.message !== undefined && props.errMsg.message !== '') {
                showToastr({
                    type: props.errMsg.type,
                    message: props.errMsg.message,
                    title: props.errMsg.title || 'Error!'
                });
            }
        }
    }, [props.notifications, props.errMsg]);

    return (
        <Fragment/>
    )

}

export default Notifications;

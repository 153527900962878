import React from "react";

import Wrapper from "../components/Wrapper";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import Navbar from "../components/Navbar";
import Content from "../components/Content";
import Footer from "../components/Footer";
import AuthGuard from "../guards/AuthGuard";

const CabinSelect = ({ children }) => (
  <React.Fragment>
      <AuthGuard>
          <Wrapper>
              <Sidebar />
              <Main>
                  <Navbar />
                  <Content>{children}</Content>
                  <Footer />
              </Main>
          </Wrapper>
      </AuthGuard>
  </React.Fragment>
);

export default CabinSelect;

import localForage from "localforage";

export const CacheKey = (key) => {
    const clientCode = localStorage.getItem(`clientCode`);
    return [clientCode, key].join(':');
}


export function clear(key, group) {
    if (group === true) {
        localForage.keys().then((keys) => {
            // An array of all the key names.
            /*Object.entries(keys).map(
                x => x[0]
            ).filter(
                x => x.substring(0, key.length) === key
            ).forEach(
                x => localStorage.removeItem(x)
            )*/
            console.log(keys)
        })

    } else {
        localForage.removeItem(CacheKey(key));
    }

}

export function set(key, value, expiryTime) {
    const expiryTimeDiff = expiryTime || process.env.REACT_EXPIRY_MINUTES || 60;
    const timeNow = new Date();
    const expires = new Date(timeNow.getTime() + (expiryTimeDiff*60000));
    localForage.setItem(CacheKey(key), { value: value, expires: expires }).then((item) => {
        return item;
    }).catch((error) => {
        console.log(error);
    })

}

export function get(key) {
    return localForage.getItem(CacheKey(key))
        .then((cachedItem) => {
            if (cachedItem !== null) {
                // Check Expiry
                const timeNow = new Date();
                const expires = new Date(cachedItem.expires);
                if (expires < timeNow) {
                    localForage.removeItem(CacheKey(key))
                    return;
                }

                return cachedItem.value;
            }
        })
        .catch((error) => {
            console.log("Something has retrieving from the Store.")
            console.log(error)
        })
}

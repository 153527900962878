import React from "react";
import { connect } from "react-redux";
import { toggleSidebar } from "../redux/actions/sidebarActions";
import { Link } from "react-router-dom";
import useAuth from "../hooks/useAuth";

import {
  Collapse,
  Navbar,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

import {
  Settings,
} from "react-feather";

const NavbarComponent = ({ dispatch }) => {
    const username = localStorage.getItem('userName');
    const { signOut } = useAuth();

    return (
        <Navbar color="white" light expand>
            <span
            className="sidebar-toggle d-flex mr-2"
            onClick={() => {
              dispatch(toggleSidebar());
            }}
            >
                <i className="hamburger align-self-center" />
            </span>

            <Collapse navbar>
                <Nav className="ml-auto" navbar>
                    <UncontrolledDropdown nav inNavbar>
                        <span className="d-inline-block d-sm-none">
                            <DropdownToggle nav caret>
                                <Settings size={18} className="align-middle" />
                            </DropdownToggle>
                        </span>
                        <span className="d-none d-sm-inline-block">
                            <DropdownToggle nav caret>
                                <span className="text-dark" style={{textTransform: 'capitalize'}}>{username}</span>
                            </DropdownToggle>
                        </span>
                        <DropdownMenu right>
                            <DropdownItem
                                onClick={signOut}
                            >
                                <Link to="/auth/sign-in" color={"red"}>
                                    Sign Out
                                </Link>
                            </DropdownItem>
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </Nav>
            </Collapse>
        </Navbar>
    );
};

export default connect(store => ({
  app: store.app
}))(NavbarComponent);

import React from "react";
import { Pie } from "react-chartjs-2";
import {
    Card, CardBody,
    CardHeader,
    CardTitle, Table,
} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSquare} from "@fortawesome/free-solid-svg-icons";

const CurrencyStatistics = (props) => {
    const theme = {
        '_0': "#2c7be5",
        '_1': "#9d7bd8",
        '_2': "#5997eb",
        '_3': "#E8EAED",
    };

    const classnames = {
        '_0': "text-primary",
        '_1': "text-secondary",
        '_2': "text-tertiary",
        '_3': "text-dark",
    };

    let dataValues = {};
    let year = '_' + new Date().getFullYear();
    let month = '_' + (new Date().getMonth() + 1);
    const currentMonth = (new Date().getMonth() + 1);
    const lastYear = (new Date().getFullYear() - 1);

    for (const cKey of Object.keys(props.dashboardData['bookings']['currency_breakdown'])) {
        // Build Data
        if (props.type === 'month') {
            let month_value = 0
            if (year in props.dashboardData['bookings']['currency_breakdown'][cKey]['months']
                && month in props.dashboardData['bookings']['currency_breakdown'][cKey]['months'][year][month]) {
                month_value = props.dashboardData['bookings']['currency_breakdown'][cKey]['months'][year][month]['total'];
            }
            dataValues[cKey] = month_value;

        } else if (props.type === 'ytd') {
            let year_value = 0
            if (year in props.dashboardData['bookings']['currency_breakdown'][cKey]['months']) {
                year_value = props.dashboardData['bookings']['currency_breakdown'][cKey]['months'][year]['total'];
            }
            dataValues[cKey] = year_value;

        } else if (props.type === 'annual') {
            let year_value = 0
            if (year in props.dashboardData['bookings']['currency_breakdown'][cKey]['months']) {
                year_value = props.dashboardData['bookings']['currency_breakdown'][cKey]['months'][year]['total'];
            }

            // Get all of the years from previous
            for (let i = 1; i <= 12; i++) {
                let month_value = 0;
                if (i > currentMonth) {
                    if ('_' + lastYear in props.dashboardData['bookings']['currency_breakdown'][cKey]['months'] &&
                        '_' + i in props.dashboardData['bookings']['currency_breakdown'][cKey]['months']['_' + lastYear]) {
                        month_value = props.dashboardData['bookings']['currency_breakdown'][cKey]['months']['_' + lastYear]['_' + i]
                    }

                    year_value += month_value;
                }
            }
            dataValues[cKey] = year_value;

        } else {
            // All Time Stats
            dataValues[cKey] = props.dashboardData['bookings']['currency_breakdown'][cKey]['total']

        }

    }

    let otherData = 0;
    const outputLabels = [];
    const outputData = [];
    const bkgColor = [];
    const keysSorted = Object.keys(dataValues).sort((a, b) => dataValues[a] - dataValues[b]);
    for (const [idx, cKey] of keysSorted.entries()) {
        if (idx < 3) {
            outputLabels.push(cKey);
            outputData.push(dataValues[cKey]);
            bkgColor.push(theme['_' + idx])
        } else {
            otherData += dataValues[cKey];
        }

    }

    if (otherData > 0) {
        outputLabels.push('Other');
        outputData.push(otherData);
        bkgColor.push(theme['_3']);

    }

    const data = {
        labels: outputLabels,
        datasets: [
            {
                data: outputData,
                backgroundColor: bkgColor,
                borderWidth: 5
            }
        ]
    };

    const options = {
        maintainAspectRatio: false,
        cutoutPercentage: 70,
        legend: {
            display: false
        }
    };

    return (
        <Card className="flex-fill w-100">
            <CardHeader>
                <span className="badge badge-primary float-right">Monthly</span>
                <CardTitle tag="h5" className="mb-0">
                    Currency
                </CardTitle>
            </CardHeader>
            <CardBody className="d-flex">
                <div className="align-self-center w-100">
                    <div className="py-3">
                        <div className="chart chart-xs">
                            <Pie data={data} options={options} />
                        </div>
                    </div>
                    <Table className="mb-0">
                        <thead>
                            <tr>
                                <th>Currency</th>
                                <th className="text-right">Revenue</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            outputData.map((dataValue, idx) => {
                                return (
                                    <tr key={`curr-${idx}`}>
                                        <td>
                                            <FontAwesomeIcon icon={faSquare} className={classnames['_'+ idx]} />{" "}
                                            {outputLabels[idx]}
                                        </td>
                                        <td className="text-right">{dataValue.toFixed(2)}</td>
                                    </tr>
                                )
                            })
                        }
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
    )
}
export default CurrencyStatistics
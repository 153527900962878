import { get as getCache, set as setCache } from './cacheWrapper'
import { getCookie } from "./cookieWrapper";

const callAPI = async (url, cacheObj) => {
    // The Token is now super important due to the decode information
    const accessToken = getCookie(`token`);

    // Default Checks need a CacheKey to use the Cache
    let pullFromCache = false;
    if (cacheObj !== undefined && cacheObj.cacheKey !== undefined) {
        pullFromCache = true;
    }

    if (cacheObj !== undefined && 'skipCache' in cacheObj && cacheObj.skipCache === true) {
        pullFromCache = false;
    }

    let cacheValue;
    if (cacheObj !== undefined && pullFromCache === true) {
        cacheValue = await getCache(cacheObj.cacheKey);
    }

    if (cacheValue !== undefined) {
        return cacheValue;
    }

    // No Cache Pull From API
    try {
        const baseURL = process.env.REACT_APP_API_URL;
        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${accessToken}`,
            },
        };

        const response = await fetch(`${baseURL}${url}`, requestOptions);
        if (response.ok) {
            const data = await response.json();
            if (cacheObj !== undefined) {
                if (!('dontCache' in cacheObj) || ('dontCache' in cacheObj && cacheObj.dontCache !== true)) {
                    setCache(cacheObj.cacheKey, data);
                }
            }

            return data;

        }

    } catch (error) {
        console.log("Problem with Fetch", error);
    }

}

export default callAPI

import React from "react";

import { createContext, useEffect, useReducer } from "react";
import {
    isValidToken, setSession, getUserFromToken, getFullNameFromToken, getClientFromToken, getUserNameFromToken
} from "../utils/jwt";
import { getCookie } from "../functions/cookieWrapper"
import callAPI from "../functions/callAPI"

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null,
};

const baseURL = process.env["REACT_APP_API_URL"];

const JWTReducer = (state, action) => {
    switch (action.type) {
        case INITIALIZE:
            return {
                isAuthenticated: action.payload.isAuthenticated,
                isInitialized: true,
                user: action.payload.user,
            };
        case SIGN_IN:
            return {
                ...state,
                isAuthenticated: true,
                user: action.payload.user,
            };
        case SIGN_OUT:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
            };
        default:
            return state;
    }
};

const AuthContext = createContext(null);

function AuthProvider({ children }) {
    const [state, dispatch] = useReducer(JWTReducer, initialState);

    useEffect(() => {
        const initialize = async () => {
            try {
                const accessToken = getCookie(`token`);
                if (accessToken && isValidToken(accessToken)) {
                    setSession(accessToken);
                    const userData = {
                        'userID': getUserFromToken(accessToken),
                        'fullName': getFullNameFromToken(accessToken),
                        'userName': getUserNameFromToken(accessToken),
                        'clientCode': getClientFromToken(accessToken),
                    }

                    dispatch({
                        type: INITIALIZE,
                        payload: {
                            isAuthenticated: true,
                            user: userData,
                        },
                    });
                } else {
                    dispatch({
                        type: INITIALIZE,
                        payload: {
                            isAuthenticated: false,
                            user: null,
                        },
                    });
                }
            } catch (err) {
                dispatch({
                    type: INITIALIZE,
                    payload: {
                        isAuthenticated: false,
                        user: null,
                    },
                });
            }
        };

        initialize();
    }, []);

    const signIn = async (userName, password) => {
        const res = await fetch(
            `${baseURL}/api/token/`,
            {
                body: JSON.stringify({
                    username: userName,
                    password: password,
                }),
                headers: {
                    'Content-Type': 'application/json',
                },
                method: 'POST'
            }
        )

        if (res.ok) {
            const data = await res.json();
            const accessToken = data.token;
            setSession(accessToken);

            // Now Get User Information
            localStorage.setItem(`userName`, getFullNameFromToken(accessToken));
            localStorage.setItem(`userUserName`, userName);

            // Now Get Client
            const clientCode = getClientFromToken(accessToken);
            localStorage.setItem(`clientCode`, clientCode);
            const clientData = await callAPI(
                `/retrieve-client/${clientCode}/`,
                {
                    skipCache: true,
                    dontCache: true,
                }
            )
            if (clientData) {
                localStorage.setItem(`clientLocale`, clientData.locale);
            }

            // Now Get Default Language
            const langData = await callAPI(
                `/cnf/language/?default_language=1`,
                {
                    skipCache: true,
                    cacheKey: 'defaultLanguage',
                }
            )
            if (Array.isArray(langData) && langData.length > 0) {
                localStorage.setItem(`defaultLanguage`, langData[0].code);
            }

            const userData = {
                'userID': getUserFromToken(accessToken),
                'fullName': getFullNameFromToken(accessToken),
                'userName': getUserNameFromToken(accessToken),
                'clientCode': getClientFromToken(accessToken),
            }

            // Sign In State
            dispatch({
                type: SIGN_IN,
                payload: {
                    user: userData,
                },
            });

        } else {
            // Initialise not authenticated
            dispatch({
                type: INITIALIZE,
                payload: {
                    isAuthenticated: false,
                    user: null,
                },
            });

            // Throw an Error to Show on the Login Page
            throw new Error("Unauthenticated. Incorrect Username and Password provided.");

        }

    };

    const signOut = async () => {
        setSession(null);
        dispatch({ type: SIGN_OUT });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                method: "jwt",
                signIn,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
}

export { AuthContext, AuthProvider };


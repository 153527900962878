import React from "react";
import { Col, Card, CardBody, CardHeader, Row } from "reactstrap";
import {faSquare} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Statistics = (props) => {
    const clientLocale = localStorage.getItem('clientLocale');
    return (
        <Row>
            <Col lg="6" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="badge badge-primary float-right">Monthly</span>
                        <h5 className="card-title mb-0">Revenue</h5>
                    </CardHeader>
                    <CardBody className="my-2">
                        <Row key={`rev`} className="d-flex align-items-center mb-4">
                            {
                                Object.keys(props.dashboardData['bookings']['currency_breakdown']).length > 0 ?
                                    Object.keys(props.dashboardData['bookings']['currency_breakdown']).map((key, idx) => {
                                        let year = '_' + new Date().getFullYear();
                                        let month = '_' + new Date().getMonth();
                                        if (year in props.dashboardData['bookings']['currency_breakdown'][key]['months']) {
                                            if (month in props.dashboardData['bookings']['currency_breakdown'][key]['months'][year]) {
                                                let month_value = props.dashboardData['bookings']['currency_breakdown'][key]['months'][year][month]['total']
                                                let currency_value = new Intl.NumberFormat(clientLocale, {
                                                    style: 'currency',
                                                    currency: key
                                                }).format(month_value);
                                                return (
                                                    <Col key={`rev-${idx}`} xs="12">
                                                        <h4 className="d-flex align-items-center mb-0 font-weight-light">
                                                            {currency_value}
                                                        </h4>
                                                    </Col>
                                                )
                                            }
                                        }
                                    })
                                    : <React.Fragment />
                            }
                        </Row>
                    </CardBody>
                </Card>
            </Col>
            <Col lg="6" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="badge badge-info float-right">Daily</span>
                        <h5 className="card-title mb-0">Bookings</h5>
                    </CardHeader>
                    <CardBody className="my-2">
                        {
                            Object.keys(props.dashboardData['bookings']['date_breakdown']).length > 0 ?
                                Object.keys(props.dashboardData['bookings']['date_breakdown']).map((key, idx) => {
                                    let year = '_' + new Date().getFullYear();
                                    if (year === key) {
                                        let month = '_' + (new Date().getMonth() + 1);
                                        let day = '_' + new Date().getDate();
                                        if (month in props.dashboardData['bookings']['date_breakdown'][key]) {
                                            let month_value = props.dashboardData['bookings']['date_breakdown'][key][month]['reservations'];

                                            let today_value = 0;
                                            if (day in props.dashboardData['bookings']['date_breakdown'][key][month]) {
                                                today_value = props.dashboardData['bookings']['date_breakdown'][key][month][day]['total']
                                            }

                                            return (
                                                <Row key={`bkg-${idx}`} className="d-flex align-items-center mb-4">
                                                    <Col xs="12">
                                                        <h4 className="d-flex align-items-center mb-0 font-weight-light">
                                                            {today_value}
                                                        </h4>
                                                        <FontAwesomeIcon icon={faSquare} className="text-info" />{" "}
                                                        {month_value} This month
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    }
                                })
                                : <React.Fragment />
                        }
                    </CardBody>
                </Card>
            </Col>
            <Col lg="6" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="badge badge-primary float-right">Monthly</span>
                        <h5 className="card-title mb-0">API Activity</h5>
                    </CardHeader>
                    <CardBody className="my-2">
                        {
                            Object.keys(props.dashboardData['api']['date_breakdown']).length > 0 ?
                                Object.keys(props.dashboardData['api']['date_breakdown']).map((key, idx) => {
                                    let year = '_' + new Date().getFullYear();
                                    if (year === key) {
                                        let year_value = props.dashboardData['api']['date_breakdown'][key]['total'] || 0;
                                        let month = '_' + (new Date().getMonth() + 1);
                                        if (month in props.dashboardData['api']['date_breakdown'][key]) {
                                            let month_value = props.dashboardData['api']['date_breakdown'][key][month]['total'] || 0;
                                            let today_value = 0;
                                            let today = '_' + new Date().getDate();
                                            if (today in props.dashboardData['api']['date_breakdown'][key][month]) {
                                                today_value = props.dashboardData['api']['date_breakdown'][key][month][today]['total'];
                                            }

                                            return (
                                                <Row key={`act-${idx}`} className="d-flex align-items-center mb-4">
                                                    <Col xs="12">
                                                        <h4 className="d-flex align-items-center mb-0 font-weight-light">
                                                            {month_value}
                                                        </h4>
                                                        <FontAwesomeIcon icon={faSquare} className="text-primary" />{" "}
                                                        {today_value} Today
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    }
                                })
                                : <React.Fragment />
                        }
                    </CardBody>
                </Card>
            </Col>
            <Col lg="6" xl="3">
                <Card className="flex-fill">
                    <CardHeader>
                        <span className="badge badge-primary float-right">Monthly</span>
                        <h5 className="card-title mb-0">Crude Look to Book</h5>
                    </CardHeader>
                    <CardBody className="my-2">
                        {
                            Object.keys(props.dashboardData['api']['date_breakdown']).length > 0 ?
                                Object.keys(props.dashboardData['api']['date_breakdown']).map(key => {
                                    let year = '_' + new Date().getFullYear();
                                    if (year === key) {
                                        let month = '_' + (new Date().getMonth() + 1);
                                        let api_month_value;
                                        let booking_month_value;
                                        if (key in props.dashboardData['api']['date_breakdown']) {
                                            if (month in props.dashboardData['api']['date_breakdown'][key]) {
                                                api_month_value = props.dashboardData['api']['date_breakdown'][key][month]['total']
                                            }
                                        }

                                        if (key in props.dashboardData['bookings']['date_breakdown']) {
                                            if (month in props.dashboardData['bookings']['date_breakdown'][key]) {
                                                booking_month_value = props.dashboardData['bookings']['date_breakdown'][key][month]['reservations']
                                            }
                                        }

                                        if (api_month_value !== undefined && booking_month_value !== undefined) {
                                            let l2b = parseInt(parseInt(api_month_value) / parseInt(booking_month_value));
                                            return (
                                                <Row key={"l2b"} className="d-flex align-items-center mb-4">
                                                    <Col xs="12">
                                                        <h2 className="d-flex align-items-center mb-0 font-weight-light">
                                                            {l2b}:1
                                                        </h2>
                                                    </Col>
                                                </Row>
                                            )
                                        }
                                    }
                                })
                                : <React.Fragment />
                        }
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
};

export default Statistics;
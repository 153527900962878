import React, {useCallback, useEffect, useState} from "react";
import { Container, Row, Col } from "reactstrap";

import Header from "./Header";
import Notifications from "../../functions/handleNotification";
import Loader from "../../components/Loader";
import Statistics from "./Statistics";
import APIRadarChart from "./APIRadar";
import CurrencyStatistics from "./CurrencyStatistics";
import OptionStatistics from "./OptionStatistics";
import {getCookie} from "../../functions/cookieWrapper";

const Dashboard = () => {
    const [loading, setLoading] = useState(true);
    const [refresh, setRefresh] = useState(true);
    const [data, setData] = useState({});

    // Default Language
    const defaultLanguage = localStorage.getItem('defaultLanguage');

    const getDashboardData = useCallback(async() => {
        const accessToken = getCookie('token');
        const baseURL = process.env.REACT_APP_API_URL;
        setLoading(true);

        const response = await fetch(
            `${baseURL}/dashboard/dashboard/`,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${accessToken}`,
                },
                method: 'GET'
            }
        );

        if (!response.ok) {
            // Add Error Handling
            throw new Error('Unable to Load Dashboard Data!')
        } else if (response.status === 204) {
            setLoading(false);
            return;
        }
        const json = await response.json();
        setData(json);
        setLoading(false);

    }, []);

    const [notificationItem, setNotificationItem] = useState({
        messages: [],
        message: '',
        title: '',
        type: 'info'
    });
    const notificationHandler = (msg) => {
        setNotificationItem(msg)
    }
    useEffect(() => {
        if (refresh) {
            getDashboardData().catch(error => {
                notificationHandler({
                    type: 'info',
                    message: error,
                    title: 'Dashboard'
                })
            });
        }
        return () => {
            setRefresh(false);
            setLoading(false);
        }
    }, [refresh, getDashboardData])

    return (
        <Container fluid className="p-0">
            <Header />
            <Notifications notifications={notificationItem} />
            <div><h1>Home</h1></div>
            {
                loading ? <Loader /> :
                    Object.keys(data).length > 0 ?
                        <React.Fragment>
                            {
                                'api' in data && 'method_breakdown' in data['api']
                                    && 'bookings' in data && 'currency_breakdown' in data['bookings']
                                    && 'date_breakdown' in data['bookings'] ?
                                    <Statistics
                                        dashboardData={data}
                                        default_language={defaultLanguage}
                                    />
                                : <React.Fragment />
                            }
                            <Row>
                                <Col lg="6" xl="3">
                                    {
                                        'bookings' in data && 'currency_breakdown' in data['bookings'] ?
                                            <CurrencyStatistics
                                                dashboardData={data}
                                            />
                                        : <React.Fragment />
                                    }
                                </Col>
                                <Col lg="6" xl="3">
                                    {
                                        'bookings' in data && 'options' in data['bookings'] ?
                                            <OptionStatistics
                                                dashboardData={data}
                                                default_language={defaultLanguage}
                                            />
                                        : <React.Fragment />
                                    }
                                </Col>
                                <Col lg="6" xl="6">
                                    {
                                        'api' in data && 'method_breakdown' in data['api'] ?
                                            <APIRadarChart
                                                dashboardData={data}
                                            />
                                        : <React.Fragment />
                                    }

                                </Col>
                            </Row>
                        </React.Fragment>
                    : <React.Fragment />
            }
        </Container>
    )
}

export default Dashboard;
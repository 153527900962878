import React, {useState} from "react";
import { useHistory } from "react-router-dom";

import {
  Button,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input
} from "reactstrap";
import Notifications from "../../functions/handleNotification";
import useAuth from "../../hooks/useAuth";

function SignIn() {
    const [FailedPost, setFailedPost] = useState({});

    const history = useHistory();
    const { signIn } = useAuth();
    const LoginUser = async event => {
        event.preventDefault()

        try {
            const userName = event.target.username.value;
            const password = event.target.password.value;
            await signIn(userName, password);
            history.push("/home/dashboard")

        } catch (error) {
            const message = error.message || "Authentication failed !!!";
            history.push("/auth/sign-in")
            setFailedPost({
                type: 'error',
                message: message
            })

        }

    }

    return(
      <React.Fragment>
        <div className="text-center mt-4">
          <h2>Welcome To Cabin Select</h2>
          <p className="lead">Sign in to your account to continue</p>
        </div>

        <Card>
          <CardBody>
            <div className="m-sm-4">
            <Notifications errMsg={FailedPost} />
              <Form onSubmit={LoginUser}>
                <FormGroup>
                  <Label>Username</Label>
                  <Input
                    bsSize="lg"
                    type="text"
                    name="username"
                    placeholder="Enter your username"
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Password</Label>
                  <Input
                    bsSize="lg"
                    type="password"
                    name="password"
                    placeholder="Enter your password"
                  />
                </FormGroup>
                <div className="text-center mt-3">
                    <Button type="submit" color="primary" size="lg">
                      Sign in
                    </Button>
                </div>
              </Form>
            </div>
          </CardBody>
        </Card>
      </React.Fragment>
    )
}

export default SignIn;

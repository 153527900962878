import React from "react";
import {Card, CardBody, CardHeader, Col, Progress, Row, Table} from "reactstrap";

const OptionStatistics = (props) => {
    let current_options = 0
    if ('options' in props.dashboardData['bookings'] && 'date_breakdown' in props.dashboardData['bookings']) {
        current_options = props.dashboardData['bookings']['options']['total'];
        let conversionRate = 0;
        if ('expired' in props.dashboardData['bookings']['options']) {
            const year = '_' + new Date().getFullYear();
            const month = '_' + (new Date().getMonth() + 1);

            let exp_month_value = 0;
            if (year in props.dashboardData['bookings']['options']['expired']['months']
                && month in props.dashboardData['bookings']['options']['expired']['months'][year]) {
                exp_month_value = props.dashboardData['bookings']['options']['expired']['months'][year][month]['total'];
            }

            let bookings_month_value = 0;
            if (year in props.dashboardData['bookings']['date_breakdown']
                && month in props.dashboardData['bookings']['date_breakdown'][year]) {
                bookings_month_value = props.dashboardData['bookings']['date_breakdown'][year][month]['reservations'];

            }

            // What is the conversion rate for the Bookings compared to options
            conversionRate = (bookings_month_value / (exp_month_value + bookings_month_value) * 100);

        }

        return (
            <Card className="flex-fill">
                <CardHeader>
                    <span className="badge badge-info float-right">Daily</span>
                    <h5 className="card-title mb-0">Options</h5>
                </CardHeader>
                <CardBody className="my-2">
                    <Row key={`opts1`} className="d-flex align-items-center mb-4">
                        <Col xs="12">
                            <h4 className="d-flex align-items-center mb-0 font-weight-light">
                                {current_options} currently open
                            </h4>
                        </Col>
                    </Row>
                    <Row key={`opts2`} className="d-flex align-items-center mb-4">
                        <Col xs="12">
                            <p>Option Conversion Rate (Current Month)</p>
                            <Progress
                                color="info"
                                value={conversionRate}
                                className="progress-sm shadow-sm mb-1"
                            />
                        </Col>
                    </Row>
                    {
                        current_options > 0 ?
                            <Table className="mb-0">
                                <thead>
                                <tr>
                                    <th>Currency</th>
                                    <th className="text-right">Revenue</th>
                                </tr>
                                </thead>
                                <tbody>
                                {
                                    Object.keys(props.dashboardData['bookings']['options']['currency_value']).map((key, idx) => {
                                        return (
                                            <tr key={`opts-${idx}`}>
                                                <td>
                                                    {key}
                                                </td>
                                                <td className="text-right">
                                                    {props.dashboardData['bookings']['options']['currency_value'][key]}
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </Table>
                        : <React.Fragment />
                    }
                </CardBody>
            </Card>
        )
    }

    return(<React.Fragment />)

}
export default OptionStatistics

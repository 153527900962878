import async from "../components/Async";

import {
  Sliders as SlidersIcon,
  Settings as SettingsIcon,
  Users as UsersIcon,
  Anchor as BuildIcon,
  DollarSign as PriceIcon,
  Briefcase as ReservationIcon,
  Activity as ReportIcon,
  Compass as ExtraIcon
} from "react-feather";

// Home
import Dashboard from "../pages/home/Dashboard";

// Auth
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import ResetPassword from "../pages/auth/ResetPassword";
import Page404 from "../pages/auth/Page404";
import Page500 from "../pages/auth/Page500";

// Configure
const Category = async(() => import("../pages/cnf/Category"));
const DepositPolicy = async(() => import("../pages/cnf/DepositPolicy"));
const DiningSeating = async(() => import("../pages/cnf/DiningSeating"));
const GroupReview = async(() => import("../pages/cnf/GroupReview"));
const Age = async(() => import("../pages/cnf/Age"));
const BedConfig = async(() => import("../pages/cnf/BedConfig"));
const Brand = async(() => import("../pages/cnf/Brand"));
const CabinAttribute = async(() => import("../pages/cnf/CabinAttribute"));
const CabinHoldDurations = async(() => import("../pages/cnf/CabinHoldDuration"));
const CancellationPolicy = async(() => import("../pages/cnf/CancellationPolicy"));
const Consortia = async(() => import("../pages/cnf/Consortia"));
const Currency = async(() => import("../pages/cnf/Currency"));
const DiningTable = async(() => import("../pages/cnf/DiningTable"));
const ImageSize = async(() => import("../pages/cnf/ImageSize"));
const Language = async(() => import("../pages/cnf/Language"));
const Market = async(() => import("../pages/cnf/Market"));
const OptionTime = async(() => import("../pages/cnf/OptionTime"));
const Port = async(() => import("../pages/cnf/Port"));
const OptionalExtraTag = async(() => import("../pages/cnf/OptionalExtraTag"));
const CruiseTag = async(() => import("../pages/cnf/CruiseTag"));

// Build
const Cruise = async(() => import("../pages/build/Cruise"));
const CruiseDetail = async(() => import("../pages/build/Cruise/Detail"));
const Grade = async(() => import("../pages/build/Grade"));
const GradeDetail = async(() => import("../pages/build/Grade/Detail"));
const Promotion = async(() => import("../pages/price/Promotion"));
const PromotionDetail = async(() => import("../pages/price/Promotion/Detail"));
const Rate = async(() => import("../pages/price/Rate"));
const RateDetail = async(() => import("../pages/price/Rate/Detail"));
const Ship = async(() => import("../pages/build/Ship"));
const ShipDetail = async(() => import("../pages/build/Ship/Detail"));

// Operations
const Agency = async(() => import("../pages/ops/Agency"));
const AgencyDetail = async(() => import("../pages/ops/Agency/Detail"));

//Images
const Images = async(() => import("../components/Images/"));

// History
const History = async(() => import("../components/History/"));

// Reservation
// const Payments = async(() => import("../pages/res/Payments"));
const Reservation = async(() => import("../pages/res/Reservation"));
const ReservationPNR = async(() => import("../pages/res/Reservation/PNR"));

// Reports
const AvlReport = async(() => import("../pages/reports/Availability"));

// Extras
const OptionalExtra = async(() => import("../pages/extra/OptionalExtra"));
const OptionalExtraDetail = async(() => import("../pages/extra/OptionalExtra/Detail"));

const cnfRoutes = {
  path: "/cnf",
  name: "Configure",
  header: "",
  badgeColor: "primary",
//  badgeText: "5",
  icon: SettingsIcon,
  containsHome: true,
  children: [
    {
      path: "/cnf/Age",
      name: "Age",
      component: Age
    },
    {
      path: "/cnf/BedConfig",
      name: "Bed Configuration",
      component: BedConfig
    },
    {
      path: "/cnf/Brand",
      name: "Brand",
      component: Brand
    },
    {
      path: "/cnf/CabinAttribute",
      name: "Cabin Attribute",
      component: CabinAttribute
    },
    {
      path: "/cnf/CabinHoldDuration",
      name: "Cabin Hold Duration",
      component: CabinHoldDurations
    },
    {
      path: "/cnf/CancellationPolicy",
      name: "Cancellation Policy",
      component: CancellationPolicy
    },
    {
      path: "/cnf/Category",
      name: "Category",
      component: Category
    },
    {
      path: "/cnf/Consortia",
      name: "Consortia",
      component: Consortia
    },
    {
      path: "/cnf/CruiseTag",
      name: "Cruise Tags",
      component: CruiseTag
    },
    {
      path: "/cnf/Currency",
      name: "Currency",
      component: Currency
    },
    {
      path: "/cnf/DepositPolicy",
      name: "Deposit Policy",
      component: DepositPolicy
    },
    {
      path: "/cnf/DiningSeating",
      name: "Dining Seating",
      component: DiningSeating
    },
    {
      path: "/cnf/DiningTable",
      name: "Dining Table",
      component: DiningTable
    },
    {
      path: "/cnf/GroupReview",
      name: "Group Review",
      component: GroupReview
    },
    {
      path: "/cnf/ImageSize",
      name: "Image Size",
      component: ImageSize
    },
    {
      path: "/cnf/Language",
      name: "Language",
      component: Language
    },
    {
      path: "/cnf/Market",
      name: "Market",
      component: Market
    },
    {
      path: "/cnf/OptionalExtraTag",
      name: "Option Extra Tags",
      component: OptionalExtraTag
    },
    {
      path: "/cnf/OptionTime",
      name: "Option Time",
      component: OptionTime
    },
    {
      path: "/cnf/Port",
      name: "Port",
      component: Port
    }
  ]
};

const buildRoutes = {
  path: "/build",
  name: "Build",
  header: "",
  badgeColor: "primary",
//  badgeText: "5",
  icon: BuildIcon,
  containsHome: true,
  children: [
    {
      path: "/build/Ship",
      name: "Ship",
      component: Ship
    },
    {
      path: "/build/Grade",
      name: "Grade",
      component: Grade
    },
    {
      path: "/build/Cruise",
      name: "Cruise",
      component: Cruise
    }
  ]
};

const priceRoutes = {
  path: "/price",
  name: "Price",
  header: "",
  badgeColor: "primary",
//  badgeText: "5",
  icon: PriceIcon,
  containsHome: true,
  children: [
    {
      path: "/price/Rate",
      name: "Rate",
      component: Rate
    },
    {
      path: "/price/Promotion",
      name: "Promotion",
      component: Promotion
    }
  ]
};

const opsRoutes = {
  path: "/ops",
  name: "Operations",
  header: "",
  badgeColor: "primary",
//  badgeText: "5",
  icon: SlidersIcon,
  containsHome: true,
  children: [
    {
      path: "/ops/Agency",
      name: "Agency",
      component: Agency
    },
  ]
};

const resRoutes = {
  path: "/res",
  name: "Reservation",
  header: "",
  badgeColor: "primary",
//  badgeText: "5",
  icon: ReservationIcon,
  containsHome: true,
  children: [
    {
      path: "/res/Reservation",
      name: "Reservation",
      component: Reservation
    }
  ]
};

const reportRoutes = {
  path: "/reports",
  name: "Reports",
  header: "",
  badgeColor: "primary",
  icon: ReportIcon,
  containHome: false,
  children: [
    {
      path: "/reports/Availability",
      name: "Availability Report",
      component: AvlReport,
    }
  ]
}

const extraRoutes = {
  path: "/extra",
  name: "Optional Extras",
  header: "",
  badgeColor: "primary",
  icon: ExtraIcon,
  containHome: false,
  children: [
    {
      path: "/extra/OptionalExtra",
      name: "Optional Extras",
      component: OptionalExtra,
    }
  ]
}

// This route is not visible in the sidebar
const privateRoutes = {
  path: "/ops",
  name: "Private",
  children: [
    {
      path: "/build/Ship/Detail",
      name: "Ship Form",
      component: ShipDetail
    },
    {
      path: "/build/Ship/Detail/:code",
      name: "Ship Form",
      component: ShipDetail
    },
    {
      path: "/price/Rate/Detail",
      name: "Rate Form",
      component: RateDetail
    },
    {
      path: "/price/Rate/Detail/:id",
      name: "Rate Form",
      component: RateDetail
    },
    {
      path: "/price/Rate/Detail/:clone/:id",
      name: "Rate Form",
      component: RateDetail
    },
    {
      path: "/build/Cruise/Detail",
      name: "Cruise Form",
      component: CruiseDetail
    },
    {
      path: "/build/Cruise/Detail/:code",
      name: "Cruise Form",
      component: CruiseDetail
    },
    {
      path: "/build/Grade/Detail/:id",
      name: "Grade Form",
      component: GradeDetail
    },
    {
      path: "/build/Grade/Detail",
      name: "Grade Form",
      component: GradeDetail
    },
    {
      path: "/ops/Agency/Detail",
      name: "Agency Form",
      component: AgencyDetail
    },
    {
      path: "/ops/Agency/Detail/:id",
      name: "Agency Form",
      component: AgencyDetail
    },
    {
      path: "/price/Promotion/Detail",
      name: "Promotion Form",
      component: PromotionDetail
    },
    {
      path: "/price/Promotion/Detail/:code",
      name: "Promotion Form",
      component: PromotionDetail
    },
    {
      path: "/price/Promotion/Detail/:clone/:code",
      name: "Promotion Form",
      component: PromotionDetail
    },
    {
      path: "/extra/OptionalExtra/Detail",
      name: "Optional Extra Form",
      component: OptionalExtraDetail
    },
    {
      path: "/extra/OptionalExtra/Detail/:code",
      name: "Optional Extra Form",
      component: OptionalExtraDetail
    },
    {
      path: "/res/Reservation/PNR/:pnr",
      name: "PNR Detail",
      component: ReservationPNR
    },
    {
      path: "/home/dashboard",
      name: "Home",
      component: Dashboard,
    },
    {
      path: "/Images",
      name: "Images",
      component: Images
    },
    {
      path: "/History",
      name: "History",
      component: History
    }
  ]
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: UsersIcon,
  badgeColor: "secondary",
  badgeText: "Special",
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/",
      name: "Sign In",
      component: SignIn
    },
    {
      path: "/auth/sign-up",
      name: "Sign Up",
      component: SignUp
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500
    }
  ]
};

// Auth specific routes
export const page = [authRoutes];

// Dashboard specific routes
export const dashboard = [
    cnfRoutes,
    buildRoutes,
    priceRoutes,
    extraRoutes,
    opsRoutes,
    resRoutes,
    reportRoutes,
    privateRoutes,
];

export const auth = [
  authRoutes
];

// All routes
export default [
    cnfRoutes,
    buildRoutes,
    priceRoutes,
    extraRoutes,
    opsRoutes,
    resRoutes,
    reportRoutes
];

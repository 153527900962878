import React from "react";
import { Radar } from "react-chartjs-2";

import {
    Card,
    CardBody,
    CardHeader,
    CardTitle,
} from "reactstrap";

const APIRadarChart = (props) => {
    const labels = [];
    const data_values = [];
    for (const [method, method_value] of Object.entries(props.dashboardData['api']['method_breakdown'])) {
        labels.push(method);
        data_values.push(method_value);
    }

    const data = {
        labels: labels,
        datasets: [
            {
                label: "API Methods",
                backgroundColor: "rgba(0, 123, 255, 0.2)",
                borderColor: "#47bac1",
                pointBackgroundColor: "#47bac1",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#47bac1",
                data: data_values
            }
        ]
    };

    const options = { maintainAspectRatio: false };

    return (
        <Card className="flex-fill w-100">
            <CardHeader>
                <span className="badge badge-success float-right">Ever</span>
                <CardTitle tag="h5" className="mb-0">
                    API Methods
                </CardTitle>
            </CardHeader>
            <CardBody className="d-flex">
                <div className="align-self-center w-100">
                    <div className="chart">
                        <Radar data={data} options={options} />
                    </div>
                </div>
            </CardBody>
        </Card>
    );
};

export default APIRadarChart;

import React from "react";
import { Container, Row, Spinner } from "reactstrap";

const Loader = () => (
    <Container fluid className={"vh-50"}>
        <Row className="justify-content-center align-self-center w-100 text-center">
            <Spinner color="primary" />
        </Row>
        <Row className="justify-content-center align-self-center w-100 text-center">
            <p>Your data is loading please bare with us...</p>
        </Row>
    </Container>
);

export default Loader;
